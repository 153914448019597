import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"


const InfoPage = ({ data }) => {
  const girlandhorse = [
    data.girlhorse1.childImageSharp.fixed,
    {
      ...data.girlhorse2.childImageSharp.fixed,
      media: `(min-width: 420px)`,
    },
  ]

  const kissing = [
    data.kiss.childImageSharp.fixed,
    {
      ...data.kiss2.childImageSharp.fixed,
      media: `(min-width: 420px)`,
    },
  ]

  return (
    <>
      <SEO title="Info" />
      <div id="info-section">
        <h1 className="page-title">
          What Are Canvas Prints?
        </h1>
        <div className="special-image-container">
          <Img fixed={girlandhorse} alt="" />
        </div>
        <p>
          Canvas prints are photographs or art reproduced on canvas through
          a digital printing process. Canvas prints may be any size, and are frequently stretched onto a
          frame and secured for hanging or table top display.               
        </p>
        <div className="special-image-container">
          <Img fixed={kissing} alt="" />
        </div>
        <p>
          <Link style={{color: 'red'}} to='/contact-us' id="contact-us-link">For canvas prints online</Link>, simply upload your photograph or digital art, and specify how to 
          finish your canvas print. Choose big canvas prints, small canvas prints or custom size canvas prints.
          Incorperate graphics and type to complete your unique custom canvas prints order.
        </p>
        <div className="special-image-container">
          <Img fluid={data.rswitch1.childImageSharp.fluid} alt="" />
        </div> 
        <div className="special-image-container">
          <Img fluid={data.rswitch2.childImageSharp.fluid} alt="" />
        </div> 
      </div>
    </>
  )
}

export default InfoPage

export const query = graphql`
{
  rswitch1: file(name: {eq: "classic_woman"}) {
    id
    childImageSharp {
      fluid(maxWidth: 300, quality: 50) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  rswitch2: file(name: {eq: "rabbit"}) {
    id
    childImageSharp {
      fluid(maxWidth: 300, quality: 50) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  kiss: file(name: {eq: "kiss"}) {
    id
    childImageSharp {
      fixed(width: 270, height: 270) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  kiss2: file(name: {eq: "kiss"}) {
    id
    childImageSharp {
      fixed(width: 369, height: 369) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  girlhorse1: file(name: {eq: "girl-and-horse"}) {
    id
    childImageSharp {
      fixed(width: 270, height: 270) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  girlhorse2: file(name: {eq: "girl-and-horse"}) {
    id
    childImageSharp {
      fixed(width: 369, height: 369) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}
`;
  

